<template>
    <div class="form-page">
        <Case v-if="!fetching" :data="data" />
        <div class="veeva-code">{{ veevaCode }}</div>
        <span
            class="flex justify-center cursor-pointer text-sm underline"
            @click="onOpenRevealInformationPopup"
        >
            羅氏揭露訊息
        </span>
    </div>
</template>

<script>
import Case from '@/components/Case.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
    components: { Case },
    data() {
        return {
            data: undefined,
            fetching: false,
            veevaCode: null
        };
    },
    created() {
        this.fetchData();
        this.updateShareDisclaimerPop(true);
    },
    methods: {
        ...mapMutations({
            updateShareDisclaimerPop: 'popup/updateShareDisclaimerPop',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState'
        }),
        ...mapActions({
            apiClient: 'api/invoke'
        }),
        async fetchData() {
            this.fetching = true;
            const PAYLOAD = {
                category: 'Case',
                function: 'find',
                data: this.$route.params.id
            };
            const RES = await this.apiClient(PAYLOAD);
            if (!RES) {
                this.$router.push({ name: 'Line_Doctor_Case_List' });
                return;
            }
            let DATA = RES.data.data.data;
            this.data = DATA;
            this.veevaCode = RES.data.veeva;
            if (!DATA) {
                DATA = RES.data;
                this.data = {
                    title: DATA.title,
                    doctorName: DATA.doctorName,
                    doctorUCI: DATA.doctorUCI,
                    hospital: DATA.hospital,
                    doctorAvatar: DATA.doctorAvatar
                };
            }
            this.fetching = false;
        },
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        }
    }
};
</script>

<style lang="scss" scoped>
.veeva-code {
    width: 100%;
    max-width: 1025px;
    box-sizing: border-box;
    color: #333;
    opacity: 0.2;
    font-size: 16px;
    padding: 0 15px;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}
</style>
